import React from "react";
import "./App.css";

const App = () => {
  return (
    <div className="absolute h-full w-full">
      <div className="font-mono text-xl bg-green-600 flex flex-col justify-center text-white m-6 rounded-xl p-6">
        <span className="text-6xl">Hey, I am Swadhin</span>
        <br />
        <span>Engineer by profession and artist by heart!</span>
        <div className="grid lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 mt-10 text-base">
          <span>
            Currently working as a{" "}
            <span className=" font-semibold">Staff Software Engineer</span> with{" "}
            <a href="https://www.zomentum.com/" target="_blank">
              Zomentum
            </a>
            !
          </span>
          <span>
            Previously used to work with Wipro to build iOS and Android
            applications.
          </span>
        </div>
      </div>
    </div>
  );
};

export default App;
